<template>
  <div class="page">
    <el-table :data="list" border="">
      <el-table-column
        label="Condition"
        prop="name"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column label="" min-width="120" align="center">
        <template v-slot="{ row }">
          <div v-if="row.editType === 1">
            <p v-for="item in timeData" :key="item.name">
              {{
                item.name
                  | fixName(
                    row.data[item.key] &&
                      row.key !== 'inPlay' &&
                      row.data['withinTime']['id']
                  )
              }}
            </p>
          </div>
          <div v-else>
            <p v-for="item in row.data.slice(0, 3)" :key="item.id">
              {{ item.name }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Weight" min-width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="row.editType === 1">
            <p
              v-for="item in timeData"
              :key="item.name"
              v-show="row.data[item.key]"
            >
              {{ row.data[item.key] && row.data[item.key]['priority']|| 0}}
            </p>
          </div>
          <div v-else>
            <p v-for="item in row.data.slice(0, 3)" :key="item.id">
              {{ item.priority }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Operation" min-width="100" align="center">
        <template v-slot="{ row }">
          <el-link type="primary" @click="edit(row)">Edit</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="titleObj[editData.editType]"
      append-to-body
      :visible.sync="showEdit"
    >
      <div v-if="editData.editType === 1">
        <el-table :data="timeData">
          <el-table-column label="Match Time" prop="name" align="center">
            <template v-slot="{ row }">
              <div v-if="row.key === 'withinTime' && editData.data[row.key]">
                Within
                <el-input-number
                  v-model="editData.data['withinTime'].id"
                  placeholder="Input"
                  style="width: 80px"
                  :max="24"
                  :min="1"
                  :step="1"
                  :controls="false"
                ></el-input-number>
                Hours
              </div>
              <div v-else>
                {{
                  row.name
                    | fixName(
                      editData.data[row.key] &&
                        row.key !== 'inPlay' &&
                        editData.data['withinTime'].id
                    )
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Weight" prop="name" align="center">
            <template v-slot="{ row }">
              <el-input-number
                v-if="editData.data[row.key]"
                v-model="editData.data[row.key].priority"
                placeholder="Input"
                :min="1"
                :max="100"
                :step="1"
                :controls="false"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-table v-else :data="editData.data">
        <el-table-column label="Sport" >
          <template v-slot="{row}">
            <select-box
            :value.sync="row.sportId"
            search-key="name"
            :label="row.sportName"
            width="160"
            label-key="sportsName"
            :changeExtraParams="changeSportsName"
            value-key="id"
            :url="STD_SEARCH_SPORT"
            @change="(e,data,key) => {
              selectChange(row,e,data,key)
            }"
            :queryOrderParams="{ page: 1, size: 500 }"
          />
          </template>
        </el-table-column>
        <el-table-column label="Region">
          <template v-slot="{row}">
             <select-box
          :value.sync="row.regionId"
          :label="row.regionName"
          width="160"
           :changeExtraParams="changeRegionName"
          search-key="name"
          label-key="name"
          value-key="id"
          @change="(e,data,key) => {
              selectChange(row,e,data,key)
            }"
          :url="STD_SEARCH_REGION"
          :queryOrderParams="{ page: 1, size: 500,deleteFlag: 1 }"
        />
          </template>
        </el-table-column>
        <el-table-column label="Tournament" v-if="editData.editType === 2" >
          <template v-slot="{row}">
             <select-box
          :url="STD_SEARCH_TOURNAMENT"
          width="230"
          :queryOrderParams="{ page: 1, size: 100,sportId: row.sportId,regionId: row.regionId }"
          :label="row.name"
          :value.sync="row.id"
          @change="(e,data,key) => {
              selectChange(row,e,data,key)
            }"
          :changeExtraParams="changeTournament"
          search-key="name"
          label-key="name"
          value-key="id"
        />
          </template>
        </el-table-column>
        <el-table-column label="Team" v-else >
          <template v-slot="{row}">
            <select-box
                :url="STD_SEARCH_TEAM"
                ref="teamH"
                width="237"
                :queryOrderParams="{
                  page: 1,
                  size: 100,
                  isVisible: 1,
                  regionId: row.regionId,
                  sportId: row.sportId
                }"
                :label="row.name"
                :value.sync="row.id"
                @change="(e,data,key) => {
              selectChange(row,e,data,key)
            }"
                :changeExtraParams="changeTournament"
                search-key="name"
                label-key="name"
                value-key="id"
              />
          </template>
        </el-table-column>
        <el-table-column label="Weight" align="center" width="140">
          <template v-slot="{row}">
            <el-input-number v-model="row.priority" style="width: 130px;" placeholder="Input"
                :min="1"
                :max="100"
                :step="1"
                :controls="false"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column align="center" width="120">
          <div slot="header">
            <el-button type="primary" plain size="mini" @click="editData.data.unshift({})">Add</el-button>
          </div>
          <template v-slot="{$index}">
            <el-button type="danger" plain size="mini" @click="editData.data.splice($index,1)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="footer">
        <el-button type="primary" @click="save">Save</el-button>
        <el-button type="" @click="showEdit = false">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.cell p {
  border: 1px solid #ccc;
  margin: 3px;
  text-align: center;
}
/deep/.el-input--medium .el-input__inner {
  text-align: center;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>

<script>
import { getPriorityConfig, savePriorityConfig } from '@/service/index'
import TableMixin from '@/mixins/standard/tableSelect'
const defaultConf = {
            inPlay: {
              priority: undefined,
              id: ''
            },
            withinTime: {
              priority: undefined,
              id: 1
            },
            withoutTime: {
              priority: undefined,
              id: 1
            }
          }
export default {
  mixins: [TableMixin],
  data() {
    return {
      changeSportsName: 'sportName',
      changeTournament: 'name',
      changeRegionName: 'regionName',
      list: [
        {
          name: 'Match Time',
          key: 'timeConfig',
          editType: 1,
          data: defaultConf
        },
        {
          name: 'Include Tournament',
          key: 'tournamentConfig',
          editType: 2,
          data: []
        },
        {
          name: 'Include Team',
          key: 'teamConfig',
          editType: 3,
          data: []
        }
      ],
      timeData: [
        {
          key: 'inPlay',
          name: 'Live'
        },
        {
          key: 'withinTime',
          name: 'Within X Hours'
        },
        {
          key: 'withoutTime',
          name: 'More than X Hours'
        }
      ],
      showEdit: false,
      editData: { editType: 1, data: [] },
      titleObj: {
        1: 'Edit Match Time',
        2: 'Edit Include Tournament',
        3: 'Edit Include Team'
      }
    }
  },
  filters: {
    fixName(val, id) {
      if (id) {
        return val.replace('X', id)
      } else {
        return val
      }
    }
  },
  methods: {
    selectChange(row,e,data, key) {
      if(e) {
        row[key] = data.label
      } else if(row[key]) {
        row[key] = ''
      }
    },
    edit(row) {
      const { data, editType, key } = row
      this.editData = { data: JSON.parse(JSON.stringify(data)), editType, key }
      this.showEdit = true
    },
    getList() {
      getPriorityConfig().then((res) => {
        if (res.code === 0) {
          let { timeConfig, teamConfig, tournamentConfig } = res.data
          if (!timeConfig) {
            let data = {}
            this.timeData.map((item) => {
              data[item.key] = {
                priority: '',
                id: ''
              }
            })
          }
          this.list[0].data = timeConfig || defaultConf
          this.list[1].data = tournamentConfig || []
          this.list[2].data = teamConfig || []
        }
      })
    },
    save() {
      const priorityConfig = {}
      const {data ,editType, key } = this.editData
      if(editType === 1 && data.withinTime.id !== data.withoutTime.id) {
        data.withoutTime.id = data.withinTime.id
      }
      priorityConfig[key] = data
      if(Array.isArray(data)) {
        if(data.length === 0) {
          return this.$message.error('Please add data')
        } else if(data.length > 100) {
          return this.$message.error('Maximum adding 100 pieces of data')
        } else if(data.some(item => !item.priority || !item.id || !item.sportId || !item.regionId)) {
          return this.$message.error('Please check data info')
        } else {
          const setList = new Set()
          const isDuplicate = data.some(item => {
            const {id, sportId, regionId} = item
            const name = `${id}-${sportId}-${regionId}`
            if(setList.has(name)) {
              return true
            } else {
              setList.add(name)
            }
          })
          if(isDuplicate) {
            return this.$message.error('Existing duplicate data')
          }
        }
        
      }
      savePriorityConfig({
        operatorName: this.$store.state.user.userInfo.name,
        priorityConfig
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('Success')
          this.getList()
          this.showEdit = false
        }
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>
